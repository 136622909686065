* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  height: auto;
  padding: 0px !important;
  background-color: #f2f2f2;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  height: 100vh;
}
